import { Button, Grid, Typography } from "@mui/material";
import React from "react";

function HeadBar(props) {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-evenly"
      sx={{ mt: 3 }}
    >
      <Grid item sx={{ flex: "1" }}>
        <Button href="/" sx={{ color: "white" }}>
          Back
        </Button>
      </Grid>
      <Grid item sx={{ flex: "1" }}>
        <Grid container alignItems="center" justifyContent="space-evenly">
          <img
            src="./luxuo-logo-white.png"
            className="App-logo"
            alt="LUXUO Logo"
            width="50%"
            sx={{ mr: "auto", ml: "auto" }}
          />
        </Grid>
      </Grid>
      <Grid item sx={{ flex: "1" }}>
        {props.name && <Typography textAlign="right">{props.name}</Typography>}
      </Grid>
    </Grid>
  );
}

export default HeadBar;
