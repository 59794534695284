import { Button, CircularProgress, Grid } from "@mui/material";
import { Container, Stack } from "@mui/system";
import React, { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { AllUsers, auth, logout } from "../Components/Query";

function Dashboard(props) {
  const [userAuth, loading] = useAuthState(auth);
  const userTableRef = useRef();
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  async function allUsers() {
    let usersQuery = await AllUsers();
    setUsers(usersQuery);
  }

  useEffect(() => {
    if (loading) return;
    if (!userAuth) return navigate("/login");
    allUsers();
  }, [loading, userAuth]);
  console.log(users);
  return (
    <Container>
      {loading ? (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        <Grid container sx={{ mt: 3, mb: 5, justifyContent: "space-between" }}>
          <Grid>
            <DownloadTableExcel
              filename="LUXUO Quiz"
              sheet="users"
              currentTableRef={userTableRef.current}
            >
              <button>
                <Button sx={{ color: "black" }}>Export to Excel</Button>
              </button>
            </DownloadTableExcel>
          </Grid>
          <Grid>
            <Button onClick={logout}>Sign Out</Button>
          </Grid>

          <table ref={userTableRef} sx={{ mt: 5 }}>
            <tbody>
              <tr>
                <th>No.</th>
                <th>NAME</th>
                <th>EMAIL</th>
                <th>PHONE</th>
                <th>INTERESTS</th>
                <th>TIME</th>
              </tr>
              {users.map((user, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.phone}</td>
                    <td>{user.interests.map((i) => i + ", ")}</td>
                    <td>{user.timeFormat}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Grid>
      )}
    </Container>
  );
}

export default Dashboard;
