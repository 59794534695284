import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import HeadBar from "../Components/HeadBar";
import { auth, checkUser, UsersQuery } from "../Components/Query";

function FindUser(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [data, setData] = useState({
    email: searchParams.get("email"),
    name: "",
  });
  const [errorMsg, setErrorMsg] = useState("");

  function handleChange(e) {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    let users = await checkUser(data.name, data.email);
    if (users.docs.length < 1) {
      setErrorMsg("No existing user found.");
    } else {
      setErrorMsg("");
      localStorage.setItem("user", JSON.stringify(data));
      navigate("/quiz");
    }
  }
  return (
    <Container>
      <HeadBar />
      <form onSubmit={handleSubmit}>
        <Grid
          container
          alignContent="center"
          justifyContent="center"
          sx={{ input: { color: "white" }, mt: 3 }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <FormControl fullWidth>
              <TextField
                required
                id="name"
                label="Name"
                name="name"
                value={data.name}
                onChange={handleChange}
                variant="filled"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <FormControl fullWidth>
              <TextField
                required
                id="email"
                label="EMAIL"
                name="email"
                variant="filled"
                value={data.email}
                type="email"
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sx={{ mb: 3 }}>
            {errorMsg && (
              <>
                <FormHelperText error>{errorMsg}</FormHelperText>
                <Button
                  href="/form"
                  sx={{ fontSize: "12px", color: "#cccccc" }}
                >
                  New User? Click here to register.
                </Button>
              </>
            )}
          </Grid>
          <Grid item sx={{ textAlign: "center" }}>
            <Button
              type="submit"
              sx={{ fontSize: "32px", color: "white", fontWeight: "800" }}
            >
              PLAY NOW
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

export default FindUser;
