import { Button, Container, Grid, Typography } from "@mui/material";
import React from "react";

function NotFound(props) {
  return (
    <Container>
      <Grid sx={{ mt: 5, textAlign: "center" }}>
        <Typography variant="h1">404</Typography>
        <Typography variant="h4" sx={{ textAlign: "center" }}>
          You reach the hidden page and found the treasure.
        </Typography>
        <Button href="/">Back To Homepage</Button>
      </Grid>
    </Container>
  );
}

export default NotFound;
