import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import "../App.css";
import { Alert, Button, Grid, LinearProgress, TextField } from "@mui/material";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { Container } from "@mui/system";
import { auth, db, logInWithEmailAndPassword } from "../Components/Query";
function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userAuth, loading] = useAuthState(auth);
  const [docId, setDocId] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    let login = await logInWithEmailAndPassword(email, password);
    switch (login.code) {
      case "auth/wrong-password":
        setErrMsg("You entered the wrong password. Try again.");
        break;
      case "auth/user-not-found":
        setErrMsg("Email Not Found.");
        break;
      default:
    }
  }

  useEffect(() => {
    if (userAuth) navigate("/dashboard");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuth, docId]);
  return (
    <div className="login">
      {loading && <LinearProgress />}
      <Container maxWidth="sm">
        {errMsg !== "" ? <Alert severity="error">{errMsg}</Alert> : ""}
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={2}
            sx={{
              // backgroundColor: "#dcdcdc",
              textAlign: "center",
              padding: "30px",
              color: "black",
              marginTop: "20px",
            }}
          >
            <Grid item xs={12}>
              <TextField
                label="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                sx={{ backgroundColor: "black", color: "white" }}
                fullWidth
                type="submit"
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
}
export default Login;
