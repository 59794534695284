import {
  collection,
  query,
  getDocs,
  getFirestore,
  where,
  addDoc,
  serverTimestamp,
  arrayUnion,
  setDoc,
  doc,
  orderBy,
  limit,
} from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { app } from "./firebase";

const db = getFirestore(app);
const auth = getAuth(app);
const usersRef = collection(db, "Users");
const matchRef = collection(db, "Matches");

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    return err;
  }
};

const logout = () => {
  signOut(auth);
};

const AllUsers = async () => {
  let allUsers = [];
  const q = query(usersRef, orderBy("score"));
  const docs = await getDocs(q);
  docs.forEach((doc) => {
    allUsers = [...allUsers, doc.data()];
  });

  return allUsers;
};

const UsersQuery = async (email) => {
  let emailClean = email.trim();
  const q = query(usersRef, where("email", "==", emailClean));
  const docs = await getDocs(q);
  return docs;
};

const RankQuery = async () => {
  let ranks = [];
  const q = query(
    usersRef,
    where("score", "!=", 0),
    orderBy("score"),
    limit(10)
  );
  const docs = await getDocs(q);
  docs.forEach((doc) => {
    ranks = [...ranks, doc.data()];
  });

  return ranks;
};

const checkUser = async (name, email) => {
  let nameClean = name.trim();
  let emailClean = email.trim();
  const q = query(
    usersRef,
    where("email", "==", emailClean.toLowerCase()),
    where("username", "==", nameClean.replace(/\s/g, "").toLowerCase())
  );
  const docs = await getDocs(q);
  return docs;
};

const addUser = async (formData) => {
  let interestsArray = formData.interests;
  let nameClean = formData.name.trim();
  let emailClean = formData.email.trim();
  let newUser = await addDoc(usersRef, {
    name: nameClean,
    username: nameClean.replace(/\s/g, "").toLowerCase(),
    email: emailClean.toLowerCase(),
    phone: formData.phone,
    interests: arrayUnion(...interestsArray),
    score: 0,
    created: serverTimestamp(),
  });
  return newUser;
};

const newMatch = async (formData, format, time) => {
  let nameClean = formData.name.trim();
  let emailClean = formData.email.trim();
  await addDoc(matchRef, {
    name: nameClean,
    username: nameClean.replace(/\s/g, "").toLowerCase(),
    email: emailClean,
    timeFormat: format,
    score: time,
    createdAt: serverTimestamp(),
  });
  let users = await UsersQuery(formData.email);
  let user;
  users.forEach((doc) => {
    user = doc.data();
  });

  let userRef = doc(db, "Users", users.docs[0].id);

  if (user.score > time || user.score === 0) {
    let updateScore = await setDoc(
      userRef,
      { score: time, timeFormat: format },
      { merge: true }
    );
    return updateScore;
  }
};

export {
  db,
  auth,
  logout,
  AllUsers,
  UsersQuery,
  RankQuery,
  addUser,
  checkUser,
  newMatch,
  logInWithEmailAndPassword,
};
