import { Box, Button, CircularProgress, Grid, Stack } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";

function Welcome(props) {
  let counter = localStorage.getItem("counter");
  const navigate = useNavigate();

  if (!counter || parseInt(counter) >= 3) {
    counter = 1;
  } else if (parseInt(counter) < 3) {
    counter = parseInt(counter) + 1;
  }
  localStorage.setItem("counter", counter);
  useEffect(() => {
    localStorage.removeItem("user");
  }, []);
  return (
    <div>
      <Grid
        container
        spacing={0}
        padding={10}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{
          minHeight: "100vh",
          backgroundImage: `url("/background.jpg")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid item>
          <img
            src="./luxuo-logo-white.png"
            className="App-logo"
            alt="LUXUO Logo"
          />
        </Grid>
        <Grid item>
          <Button
            href="/form"
            sx={{ fontSize: "40px", color: "white", fontWeight: "800" }}
          >
            ENTER
          </Button>
        </Grid>
        <Grid item>
          <Button href="/finduser" sx={{ fontSize: "12px", color: "#cccccc" }}>
            Already played before?
          </Button>
        </Grid>
        <Box sx={{ textAlign: "center", position: "absolute", bottom: 15 }}>
          <img
            src="/hm-logo.png"
            width="50px"
            height="auto"
            alt="Heart Media Logo"
          />
        </Box>
      </Grid>
    </div>
  );
}

export default Welcome;
