import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { UsersQuery } from "./Components/Query";

function QueryTest(props) {
  const [uemail, setEmail] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  async function handleSubmit() {
    let users = await UsersQuery(uemail);
    users.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());
    });
  }
  return (
    <div>
      <TextField
        onChange={handleChange}
        name="email"
        id="email"
        value={uemail}
        label="Email"
      />
      <Button onClick={handleSubmit}>Query</Button>
    </div>
  );
}

export default QueryTest;
