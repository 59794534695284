import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import React, { useState } from "react";
import { useEffect } from "react";
import Puzzle, { PuzzleTouch } from "react-image-puzzle-touch";
import { useNavigate } from "react-router-dom";
import { useStopwatch } from "react-use-stopwatch";
import HeadBar from "../Components/HeadBar";
import Leaderboard from "../Components/Leaderboard";
import { newMatch, RankQuery } from "../Components/Query";

function Quiz(props) {
  const userLS = localStorage.getItem("user");
  const user = JSON.parse(userLS);
  let counter = localStorage.getItem("counter")
    ? localStorage.getItem("counter")
    : 1;
  const [status, setStatus] = useState("");
  const [{ time, format }, start, stop] = useStopwatch();
  const [errUser, setErrUser] = useState(true);
  const [users, setUsers] = useState([]);
  const [data, setData] = useState({
    name: user !== null ? user.name : "",
    email: user !== null ? user.email : "",
  });
  const navigate = useNavigate();

  const EndScreen = (props) => {
    return (
      <Grid sx={{ position: "absolute", top: "25%", textAlign: "center" }}>
        {props.status === "giveup" ? (
          <Alert severity="error">You gave up.</Alert>
        ) : (
          <Typography sx={{ fontSize: "2em" }}>Your Time: {format}</Typography>
        )}

        <Grid sx={{ mt: 2 }}>
          <Button
            onClick={restart}
            variant="contained"
            sx={{ bgcolor: "black", color: "white" }}
          >
            PLAY AGAIN
          </Button>
        </Grid>
        <Grid sx={{ mt: 2 }}>
          <Button
            href="/"
            variant="contained"
            sx={{ bgcolor: "black", color: "white" }}
          >
            MAIN MENU
          </Button>
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    if (userLS == null) {
      setErrUser(true);
      navigate("/form", { state: { errUser: errUser } });
    } else {
      setErrUser(false);
    }
  }, [userLS]);

  async function ranking() {
    let usersQuery = await RankQuery();
    setUsers(usersQuery);
  }

  useEffect(() => {
    ranking();
  }, [status]);

  function is_touch_device() {
    try {
      document.createEvent("TouchEvent");
      return true;
    } catch (e) {
      return false;
    }
  }

  function timerstart() {
    setStatus("start");
    start();
  }
  async function done() {
    setStatus("pause");
    stop();
    await newMatch(data, format, time).then((res) => {
      localStorage.setItem("score", time);
    });
    ranking();
  }

  function restart() {
    setStatus("reset");
    window.location.reload(false);
    if (parseInt(counter) < 3) {
      localStorage.setItem("counter", parseInt(counter) + 1);
    } else {
      localStorage.setItem("counter", 1);
    }
  }

  function giveup() {
    setStatus("giveup");
    stop();
  }

  return (
    <Container>
      <HeadBar name={user && user.name} />

      <Grid>
        <Grid sx={{ mb: 3, mt: 3, textAlign: "center" }}>
          {status !== "pause" && <strong>{format}</strong>}
        </Grid>

        <Grid container alignContent="center" justifyContent="center">
          <Grid item sx={{ position: "relative", textAlign: "center" }}>
            {is_touch_device() ? (
              <PuzzleTouch
                size="600"
                image={`./quiz${counter}.jpg`}
                onDone={done}
              />
            ) : (
              <Puzzle size="600" image={`./quiz${counter}.jpg`} onDone={done} />
            )}
            <Paper
              sx={{
                display: status === "start" ? "none" : "block",
                position: "absolute",
                width: "100%",
                height: "102%",
                top: 0,
                left: 0,
                opacity: "90%",
              }}
            />
            {status === "" ? (
              <Button
                onClick={() => timerstart()}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  bgcolor: "black",
                  color: "white",
                }}
                size="large"
                variant="contained"
              >
                START
              </Button>
            ) : (
              ""
            )}
          </Grid>
          {status === "pause" || status === "giveup" ? (
            <EndScreen status={status} />
          ) : (
            ""
          )}
        </Grid>
        <Grid sx={{ mt: 3 }} textAlign="center">
          {status === "start" && (
            <Button onClick={giveup} variant="outlined" color="error">
              Give Up
            </Button>
          )}
        </Grid>
        <Leaderboard users={users} />
      </Grid>
    </Container>
  );
}

export default Quiz;
