import { createTheme, ThemeProvider } from "@mui/material/styles";
import { orange, red } from "@mui/material/colors";
import { Box } from "@mui/system";
import React from "react";
import "../App.css";

const Layout = ({ children }) => {
  const mdTheme = createTheme({
    palette: {
      mode: "dark",
      secondary: {
        main: orange[500],
        danger: red[500],
      },
    },
  });
  return (
    <ThemeProvider theme={mdTheme}>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "dark"
              ? theme.palette.grey[900]
              : theme.palette.grey[100],
          color: "white",
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        {children}
      </Box>
    </ThemeProvider>
  );
};

export default Layout;
