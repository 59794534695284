import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Leaderboard from "./Components/Leaderboard";
import Layout from "./Layout";
import NotFound from "./Pages/404";
import FindUser from "./Pages/FindUser";
import Form from "./Pages/Form";
import Login from "./Pages/Login";
import Quiz from "./Pages/Quiz";
import Welcome from "./Pages/Welcome";
import QueryTest from "./QueryTest";
import Dashboard from "./Admin/Dashboard";

function App() {
  return (
    <>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Welcome />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/form" element={<Form />} />
            <Route path="/finduser" element={<FindUser />} />
            <Route path="/quiz" element={<Quiz />} />
            <Route path="/test" element={<QueryTest />} />
            <Route path="/leaderboard" element={<Leaderboard />} />
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </Layout>
      </Router>
    </>
  );
}

export default App;
