import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";

function Leaderboard(props) {
  const userLS = localStorage.getItem("user");
  const userParse = JSON.parse(userLS);
  let userClean = "";
  if (userLS) {
    userClean = userParse.name.trim().replace(/\s/g, "").toLowerCase();
  }

  return (
    <>
      <Grid sx={{ mt: 5 }}>
        <Typography variant="h5" sx={{ color: "white", textAlign: "center" }}>
          LEADERBOARD
        </Typography>
      </Grid>
      <Grid
        container
        alignContent="center"
        justifyContent="center"
        sx={{ width: "100%" }}
      >
        <Grid>
          <List dense sx={{ width: "100%" }}>
            {props.users.length > 0 &&
              props.users.map((user, index) => {
                return (
                  <span key={index}>
                    <ListItem
                      sx={
                        userClean === user.username
                          ? { color: "yellow" }
                          : { color: "white" }
                      }
                      secondaryAction={
                        <ListItemText primary={user.timeFormat} edge="end" />
                      }
                    >
                      <ListItemIcon
                        sx={
                          userClean === user.username
                            ? { color: "yellow" }
                            : { color: "white" }
                        }
                      >
                        {index + 1}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          userClean === user.username ? "You" : user.name
                        }
                        sx={{ mr: 8 }}
                      />
                    </ListItem>
                    <Divider />
                  </span>
                );
              })}
          </List>
        </Grid>
      </Grid>
    </>
  );
}

export default Leaderboard;
