import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { Container } from "@mui/system";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { addUser, UsersQuery } from "../Components/Query";
import { useEffect } from "react";
import HeadBar from "../Components/HeadBar";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "white",
  },
  "& label.Mui-root": {
    color: "white",
  },
});

function Form(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [errUser, setErrUser] = useState(false);
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    interests: [],
    tos: false,
  });
  const [errorMsg, setErrorMsg] = useState({
    errName: "",
    errEmail: "",
    errInterest: "",
    errTOS: "",
  });
  const [isFormValid, setIsFormValid] = useState(true);

  let interestList = [
    "MEN'S FASHION",
    "WOMEN'S FASHION",
    "WATCHES",
    "YACHTING LIFESTYLE",
  ];

  function handleChange(e) {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  function toggleTOS() {
    if (data.tos === true) setData({ ...data, tos: false });
    else setData({ ...data, tos: true });
  }

  const addRemoveInterest = (interest) => {
    const newInterests = [...data.interests];
    const index = newInterests.indexOf(interest);
    if (index === -1) {
      newInterests.push(interest);
    } else {
      newInterests.splice(index, 1);
    }
    setData({ ...data, interests: newInterests });
  };

  function formValidate() {
    let errName = "";
    let errEmail = "";
    let errInterest = "";
    let errTOS = "";
    let formValid = true;
    if (data.name === "") {
      errName = "Name is required";
      formValid = false;
    }

    if (data.email === "") {
      errEmail = "Email is required";
      formValid = false;
    } else if (data.email !== "" && errorMsg.errEmail) {
      errEmail = errorMsg.errEmail;
      formValid = false;
    }
    if (data.interests.length < 1) {
      errInterest = "Choose at least one interest.";
      formValid = false;
    }
    if (data.tos === false) {
      errTOS = "You must agree";
      formValid = false;
    }
    setErrorMsg({
      ...errorMsg,
      errName: errName,
      errEmail: errEmail,
      errInterest: errInterest,
      errTOS: errTOS,
    });
    setIsFormValid(formValid);
  }

  async function checkEmail(e) {
    let formValid = true;
    let users = await UsersQuery(e.target.value);
    let emailValidateRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]+$/;

    if (users.docs.length > 0) {
      setErrorMsg({ ...errorMsg, errEmail: "Email already exists." });
      formValid = false;
    } else if (data.email === "") {
      setErrorMsg({ ...errorMsg, errEmail: "Email is required." });
      formValid = false;
    } else if (!emailValidateRegex.test(e.target.value)) {
      setErrorMsg({
        ...errorMsg,
        errEmail: "Please enter the valid email address.",
      });
      formValid = false;
    } else {
      setErrorMsg({ ...errorMsg, errEmail: "" });
      formValid = true;
    }
    setIsFormValid(formValid);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (isFormValid) {
      await addUser(data).then((res) => {
        localStorage.setItem("user", JSON.stringify(data));
      });
      navigate("/quiz");
    } else {
      console.log(errorMsg);
    }
  }

  useEffect(() => {
    if (location.state) {
      setErrUser(true);
    }
  }, [location.state]);

  return (
    <Container>
      <HeadBar />
      <Grid>
        <Grid sx={{ mt: 3 }}>
          {errUser && (
            <Alert severity="error">
              No user information found. Please fill the following form first to
              play the quiz.
            </Alert>
          )}
        </Grid>

        <form onSubmit={handleSubmit} noValidate>
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="center"
            sx={{ input: { color: "white" }, mt: 1 }}
          >
            <Grid item xs={12}>
              <FormControl fullWidth>
                <CssTextField
                  required
                  id="name"
                  label="Name"
                  name="name"
                  value={data.name}
                  onChange={handleChange}
                  variant="filled"
                  error={errorMsg.errName !== ""}
                  helperText={errorMsg.errName !== "" ? errorMsg.errName : " "}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  required
                  id="email"
                  label="EMAIL"
                  name="email"
                  variant="filled"
                  type="email"
                  onChange={handleChange}
                  onBlur={checkEmail}
                  error={errorMsg.errEmail !== ""}
                  helperText={
                    errorMsg.errEmail !== "" ? errorMsg.errEmail : " "
                  }
                />
              </FormControl>
              {errorMsg.errEmail === "Email already exists." && (
                <Button
                  href={`/finduser?email=${data.email}`}
                  sx={{ fontSize: "12px", color: "#cccccc" }}
                >
                  Already played before?
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  id="phone"
                  label="PHONE"
                  name="phone"
                  variant="filled"
                  type="phone"
                  onChange={handleChange}
                  helperText=" "
                />
              </FormControl>
            </Grid>
            <Grid item sx={{ mr: "auto" }}>
              <FormLabel
                required
                error={errorMsg.errInterest !== ""}
                helpertext={errorMsg.errEmail !== "" ? errorMsg.errEmail : " "}
              >
                INTERESTS
              </FormLabel>
              <FormGroup row>
                {interestList.map((item) => {
                  return (
                    <Grid key={item}>
                      <FormControlLabel
                        sx={{ mr: 6 }}
                        control={
                          <Checkbox onClick={() => addRemoveInterest(item)} />
                        }
                        label={item}
                      />
                    </Grid>
                  );
                })}
              </FormGroup>
              {errorMsg.errInterest !== "" && (
                <FormHelperText error>
                  Choose at least one interest.
                </FormHelperText>
              )}
            </Grid>
            <Grid item>
              <FormControl>
                <FormControlLabel
                  control={<Checkbox onClick={toggleTOS} />}
                  label="You agree that may collect, use and disclose your personal data, which you have provided in this form, for providing marketing material that you have agreed to receive, in accordance with the Personal Data Protection Act 2012"
                />
              </FormControl>
              {errorMsg.errTOS !== "" && (
                <FormHelperText error>
                  You must agree our terms and conditions.
                </FormHelperText>
              )}
            </Grid>
            <Grid item>
              <Button
                type="submit"
                onClick={formValidate}
                size="large"
                sx={{ fontSize: "32px", color: "white", fontWeight: "800" }}
              >
                PLAY NOW
              </Button>
            </Grid>
          </Grid>
          <Grid>
            {!isFormValid && (
              <Alert severity="error">
                There are some items that require your attention.
              </Alert>
            )}
          </Grid>
        </form>
      </Grid>
    </Container>
  );
}

export default Form;
